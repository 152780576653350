import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IconSmallArrowRight } from '../../../../Icons/IconSmallArrowRight';

import classes from './OuterCategoryContainer.module.scss';

export function OuterCategoryContainer({
  setCatalogButtonStyle,
  setIsHoverCatalog,
  outerCategoryObj,
  showDropDownPanel,
  setCategoriesListDropDownPanel,
  setCurrentOuterCategory,
}) {
  const { t } = useTranslation();
  const [arrowColor, setArrowColor] = useState('white');

  return (
    <div
      className={classes.outerCategory}
      onMouseEnter={() => setArrowColor('#ee763c')}
      onMouseLeave={() => setArrowColor('white')}
    >
      <Link
        key={outerCategoryObj.outerCategory}
        onClick={() => {
          setIsHoverCatalog(false);
          setCatalogButtonStyle('catalog_button');
        }}
        to={`outercategory/${outerCategoryObj.outerCategory}`}
      >
        <span>{t(`outerCategory:${outerCategoryObj.outerCategory}:title`)}</span>
      </Link>
      <button
        type='button'
        className={classes.arrowRightButton}
        onClick={() => {
          showDropDownPanel(true);
          setCategoriesListDropDownPanel(outerCategoryObj.categories);
          setCurrentOuterCategory(outerCategoryObj.outerCategory);
        }}
      >
        <IconSmallArrowRight color={arrowColor} size={30} />
      </button>
    </div>
  );
}
