import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IconLeftArrow } from '../../Icons/IconLeftArrow';
import { IconRightArrow } from '../../Icons/IconRightArrow';
import { IconGrid } from '../../Icons/IconGrid';
import { SoshialMediaIconsContainer } from '../../SoshialMediaIconsContainer/SoshialMediaIconsContainer';
import { IconLoginPerson } from '../../Icons/IconLoginPerson';
import lamaWhite from '../../../assets/lamaWhite.png';

import classes from './SmallDropDown.module.scss';

export function SmallDropDown({
  makeMainContentUnscrollable,
  makeMainContentScrollable,
  setOuterCategorySmallContainer,
  showCallBackForm,
  langButtonState,
  setLangButtonState,
  changeLanguage,
  hideSmallDropDownContainer,
  hideMainSmallDropDown,
  setAuthTableState,
}) {
  const { t } = useTranslation();
  const { name } = useSelector((state) => state.UserReducer);
  const navigate = useNavigate();

  return (
    <div itemScope itemType='https://schema.org/Organization' className={classes.smallDropDown}>
      <div className={classes.company_name_and_logo}>
        <button
          type='button'
          className={classes.left_arrow_button}
          onClick={() => {
            hideSmallDropDownContainer();
            makeMainContentScrollable();
          }}
        >
          <IconLeftArrow size={40} color='#FFFFF0' />
        </button>
        <div className={classes.logoNameWrapper}>
          <span itemProp='brand' className={classes.woolama_name}>
            WoooLama
          </span>
        </div>
        <div className={classes.logoImgWrapper}>
          <img
            className={classes.wooLamaLogoImage}
            itemProp='logo'
            src={lamaWhite}
            alt='wooolama інтернет магазин'
          />
        </div>
      </div>
      <div className={classes.catalog}>
        <button
          type='button'
          className={classes.catalog_button}
          onClick={() => {
            hideMainSmallDropDown();
            setOuterCategorySmallContainer(true);
          }}
        >
          <div className={classes.button_description}>
            <div className={classes.catalog_and_image_container}>
              <IconGrid size={30} color='#FFFFF0' />
              <h1 className={classes.catalog_word}>{t('static:catalog')}</h1>
            </div>
            <IconRightArrow size={23} color='#FFFFF0' />
          </div>
        </button>
      </div>

      <div className={classes.additional_links}>
        <Link to='viber://chat?number=%2B380955025286'>
          <span>Viber</span>
        </Link>
        <Link to='https://t.me/LarisaSalmanova'>
          <span>Telegram</span>
        </Link>
        <Link to='/contacts'>{t('static:contacts')}</Link>
        <Link to='paymentanddelivery'>{t('static:paymentsAndDelivery')}</Link>
      </div>

      <div
        className={classes.login_section}
        onClick={() => {
          if (name) {
            navigate('/userpage');
            hideSmallDropDownContainer();
            makeMainContentScrollable();
          } else {
            setAuthTableState((prev) => !prev);
            makeMainContentUnscrollable();
          }
        }}
      >
        {name || (
          <>
            <IconLoginPerson size={40} color='#FFFFF0' />
            <span>Login</span>
          </>
        )}
      </div>
      <div
        itemScope
        itemType='https://schema.org/Organization'
        className={classes.contact_phones_and_callback}
      >
        <div className={classes.phoneContainer}>
          <span itemProp='telephone' className={classes.phone_number}>
            0955025286
          </span>
          <span itemProp='employee' className={classes.employeeName}>
            {t('static:inna')}
          </span>
        </div>
        <div className={classes.phoneContainer}>
          <span itemProp='telephone' className={classes.phone_number}>
            0957729338
          </span>
          <span itemProp='employee' className={classes.employeeName}>
            {t('static:larisa')}
          </span>
        </div>

        <span itemProp='email' className={classes.email}>
          sales@wooolama.com
        </span>
        <button
          className={classes.callback_button}
          onClick={() => {
            showCallBackForm((prev) => !prev);
            makeMainContentUnscrollable();
          }}
        >
          {t('static:callback')}
        </button>
      </div>
      <div className={classes.change_language_section}>
        <button
          type='button'
          className={langButtonState === 'ua' ? classes.ua_lang_button_choosen : classes.ua_lang_button}
          onClick={(e) => {
            setLangButtonState(() => 'ua');
            changeLanguage('ua');
            localStorage.setItem('language', 'ua');
            window.location.reload();
          }}
        >
          Укр
        </button>
        <button
          type='button'
          className={langButtonState === 'en' ? classes.en_lang_button_choosen : classes.en_lang_button}
          onClick={(e) => {
            setLangButtonState(() => 'en');
            changeLanguage('en');
            localStorage.setItem('language', 'en');
            window.location.reload();
          }}
        >
          Eng
        </button>
      </div>
      <div className={classes.sochial_media_links_container}>
        <SoshialMediaIconsContainer />
      </div>
    </div>
  );
}
