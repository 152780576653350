import React from 'react';

export function IconLoginPerson({ size, color }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      viewBox='11 8 74 80'
      style={{ width: `${size}px`, height: `${size}px` }}
      xmlSpace='preserve'
    >
      <title>LoginPerson</title>
      <g>
        <g>
          <path
            fill={color}
            d='M65.5,34.6c0-8.6-6.9-15.5-15.5-15.5c-8.6,0-15.5,6.9-15.5,15.5C34.5,43.1,41.4,50,50,50C58.6,50,65.5,43.1,65.5,34.6z     M38.5,34.6c0-6.3,5.2-11.5,11.5-11.5c6.3,0,11.5,5.2,11.5,11.5C61.5,40.9,56.3,46,50,46C43.7,46,38.5,40.9,38.5,34.6z'
          />
        </g>
        <g>
          <path
            fill={color}
            d='M25.3,72c-1.8,4.4,1.4,9.3,6.2,9.3h37.8c4.8,0,8-4.9,6.2-9.3c-4.7-11.2-14.2-18.8-25.1-18.8C39.4,53.2,29.9,60.8,25.3,72z     M50.4,57.2c9,0,17.2,6.3,21.4,16.4c0.5,1.2,0,2.2-0.2,2.5c-0.2,0.4-1,1.2-2.3,1.2H31.5c-1.3,0-2-0.8-2.3-1.2    c-0.2-0.4-0.7-1.3-0.2-2.5C33.2,63.5,41.4,57.2,50.4,57.2z'
          />
        </g>
      </g>
    </svg>
  );
}
