 export function copyItems({outercategory, category, type, currentItems, t}) {
    let currentItemsCopy = [];
    
    if (currentItems && currentItems.length) {

      currentItems.forEach((item) => {
        let objCopy = Object.assign({}, item);

        if (item._id === 'allgoods') return;
        if (outercategory && category && type === 'catalogs') {
          objCopy.title = t(`subcategories:${item._id}:title`);
          objCopy.link = item.title;
        }

        if(outercategory && !category && type === 'catalogs') {
          objCopy.link = item.category;
        }

        if(!outercategory && category && type === 'catalogs') {
          objCopy.title = t(`subcategories:${item._id}:title`);
          objCopy.link = item.title;
        }

        if(!outercategory && !category && type === 'catalogs') {
          if(objCopy.outerCategory) {
            objCopy.title = t(`outerCategory:${item.outerCategory}:title`);
            objCopy.link = objCopy.outerCategory;
          }
          if(!objCopy.outerCategory && objCopy.category) {
            objCopy.title = t(`category:${item.category}`);
            objCopy.link = objCopy.category;
          }
        }
        currentItemsCopy.push(objCopy);
      });
    }
    return currentItemsCopy;
}