import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Cards } from './Cards/Cards';
import { IconLeftArrow } from '../../Icons/IconLeftArrow';

import placeholder from '../../../assets/placeholder.webp';

import classes from './SmallSubCategoriesDropDown.module.scss';

export function SmallSubCategoriesDropDown({
  makeMainContentScrollable,
  categoryForSubCategory,
  hideSubSmallDropDown,
  hideSmallDropDownContainer,
  showSmallCatalogDropDown,
  setOuterCategorySmallContainer,
}) {
  const { t } = useTranslation();

  const { categoriesList } = useSelector((state) => state.GoodsReducer);
  const { catalogs } = useSelector((state) => state.CatalogReducer);

  const [subCategories, setSubCategories] = useState([]);
  const [hasOuterCategory, setHasOuterCategory] = useState(false);
  const [currentOuterCategory, setCurrentOuterCategory] = useState();

  useEffect(() => {
    categoriesList &&
      categoriesList.forEach((outerCategoryObj) => {
        if (outerCategoryObj.categories.includes(categoryForSubCategory)) {
          if (outerCategoryObj.outerCategory === 'noOuterCategory') {
            setHasOuterCategory(false);
          } else {
            setHasOuterCategory(true);
            setCurrentOuterCategory(outerCategoryObj.outerCategory);
          }
        }
      });
  }, [categoriesList, categoryForSubCategory]);

  useEffect(() => {
    const catalog = catalogs.find((catalog) => catalog.category === categoryForSubCategory);
    const subCategories = (catalog && catalog.subCategories) || null;

    setSubCategories([
      ...subCategories,
      { _id: 'allgoods', previewImage: catalog.previewImage || placeholder, title: 'allgoods' },
    ]);
  }, [catalogs, categoryForSubCategory]);

  return (
    <div className={classes.small_subcategories_dropdown}>
      <div className={classes.back_link_to_catalogs_and_subcategory_name}>
        <button
          type='button'
          onClick={() => {
            hideSubSmallDropDown();
            hasOuterCategory ? showSmallCatalogDropDown() : setOuterCategorySmallContainer(true);
          }}
          className={classes.backButton}
        >
          <IconLeftArrow size={30} color='black' />
        </button>
        <h1 itemProp='title' className={classes.subcategory_name}>
          {t(`category:${categoryForSubCategory}`)}
        </h1>
      </div>
      <nav className={classes.cards_container}>
        <Cards
          currentOuterCategory={currentOuterCategory}
          category={categoryForSubCategory}
          makeMainContentScrollable={makeMainContentScrollable}
          hideSmallDropDownContainer={hideSmallDropDownContainer}
          subCategories={subCategories}
        />
      </nav>
    </div>
  );
}
