import React, { useEffect, useState, useRef, createRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useWindowDimensions } from '../../../helpers/useWindowDimension';
import { CardItem } from '../../CardItems/CardItem';
import { handleLeftClick, handleRightClick } from '../../../helpers/handleCaruselClick';
import { IconLeftArrow } from '../../Icons/IconLeftArrow';
import { IconRightArrow } from '../../Icons/IconRightArrow';

import classes from './DiscountCarusel.module.scss';

export function DiscountCarusel({
  stateToRerenderBuyButton,
  setStateToRerenderBuyButton,
  setShoppingCardPreview,
  makeMainContentUnscrollable,
}) {
  const { width } = useWindowDimensions();
  const { t } = useTranslation();

  const { goodsArrays } = useSelector((state) => state.GoodsReducer);
  const [discountGoods, setDiscountGoods] = useState([]);
  const [translateCaruselLeft, setTranslateCaruselLeft] = useState(0);
  const [cardsShown, setCardsShown] = useState(1);
  const [arrowsSize, setArrowsSize] = useState(55);
  const itemContainerRef = useRef([]);

  useEffect(() => {
    if (width > 1280) {
      setCardsShown(4);
    }
    if (width >= 960 && width <= 1280) {
      setCardsShown(3);
    }

    if (width >= 650 && width <= 960) {
      setCardsShown(2);
      setArrowsSize(35);
    }

    if (width < 650) {
      setCardsShown(1);
      setArrowsSize(25);
    }
  }, [width]);

  useEffect(() => {
    const discountGoods = [];
    for (const goodCategory of goodsArrays) {
      for (const good of goodCategory.goods) {
        if (good.discount.hasDiscount || good.popular || good.newGoodState) {
          discountGoods.push(good);
        }
      }
    }
    setDiscountGoods(discountGoods);
  }, [goodsArrays]);

  itemContainerRef.current = new Array(discountGoods.length)
    .fill(null)
    .map((_, i) => itemContainerRef.current[i] ?? createRef());

  return (
    <div className={classes.caruselContainer}>
      <div className={classes.caruselTitle}>{t('static:speshialPropositions')}</div>
      {discountGoods.length ? (
        <div className={classes.carusel}>
          {width > 400 && (
            <button
              type='button'
              className={classes.leftArrow}
              onClick={() => {
                handleLeftClick(translateCaruselLeft, setTranslateCaruselLeft, itemContainerRef, cardsShown);
              }}
            >
              <IconLeftArrow size={arrowsSize} color='black' />
            </button>
          )}
          <div className={classes.container}>
            {discountGoods &&
              discountGoods.map((good, i) => (
                <div className={classes.itemContainer} ref={itemContainerRef.current[i]} key={good._id}>
                  <CardItem
                    good={good}
                    stateToRerenderBuyButton={stateToRerenderBuyButton}
                    setStateToRerenderBuyButton={setStateToRerenderBuyButton}
                    setShoppingCardPreview={setShoppingCardPreview}
                    makeMainContentUnscrollable={makeMainContentUnscrollable}
                  />
                </div>
              ))}
          </div>
          {width > 400 && (
            <button
              type='button'
              className={classes.rightArrow}
              onClick={() => {
                handleRightClick(translateCaruselLeft, setTranslateCaruselLeft, itemContainerRef, cardsShown);
              }}
            >
              {translateCaruselLeft !== 0 ? (
                <IconRightArrow size={arrowsSize} color='black' />
              ) : (
                <span className={classes.emptySpaceRightArrow}> ffffffffffff</span>
              )}
            </button>
          )}
        </div>
      ) : (
        <div className={classes.noGoodsFound}>Немає товарів</div>
      )}
      {width <= 400 && (
        <div className={classes.buttonsForSmallScreen}>
          <button
            type='button'
            className={classes.leftArrow}
            onClick={(e) => {
              if (!itemContainerRef || itemContainerRef.current.length <= 0) return;
              handleLeftClick(translateCaruselLeft, setTranslateCaruselLeft, itemContainerRef, cardsShown);
            }}
          >
            <IconLeftArrow size={40} color='black' />
          </button>

          <button
            type='button'
            className={classes.rightArrow}
            onClick={(e) => {
              if (!itemContainerRef || itemContainerRef.current.length <= 0) return;
              handleRightClick(translateCaruselLeft, setTranslateCaruselLeft, itemContainerRef, cardsShown);
            }}
          >
            <IconRightArrow size={40} color='black' />
          </button>
        </div>
      )}
    </div>
  );
}
