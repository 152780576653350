import React, { useEffect, useState, useRef, lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

import { LoadingPage } from '../../LoadingPage/LoadingPage';
import { SubCategoriesDropDown } from '../SubCategoriesDropDown/SubCategoriesDropDown';
import { hasSubCategories } from '../../../helpers/hasSubcategories';
import { IconLeftArrow } from '../../Icons/IconLeftArrow';

import classes from './DropDownPanel.module.scss';

const DropDownPanelAdmin = lazy(() =>
  import('./DropDownPanelAdmin').then((module) => ({
    default: module.DropDownPanelAdmin,
  })),
);

export function DropDownPanel({
  setCatalogButtonStyle,
  setIsHoverCatalog,
  showChangeCategorySectionForm,
  showFormAddCategory,
  showAskFullDeletionForm,
  setCategoryTarget,
  showAddSubCategoryForm,
  showDropDownPanel,
  setCurrentOuterCategory,
  hideDropDownMenu,
  currentOuterCategory,
}) {
  const { t } = useTranslation();
  const { Role } = useSelector((state) => state.UserReducer);
  const { categoriesList: outerCategoriesList } = useSelector((state) => state.GoodsReducer);
  const { catalogs } = useSelector((state) => state.CatalogReducer);

  const [subCategoriesDropDown, showSubCategoriesDropDown] = useState(false);
  const [categoryForSubCategory, setCategoryForSubCategory] = useState('');
  const [categoriesList, setCategoriesList] = useState([]);
  const [arrowColor, setArrowColor] = useState('rgb(231, 121, 121)');

  useEffect(() => {
    const outerCategory = outerCategoriesList.find(
      (outerCategoryObj) => outerCategoryObj.outerCategory === currentOuterCategory,
    );

    outerCategory && setCategoriesList(outerCategory.categories);
  }, [currentOuterCategory, outerCategoriesList]);

  const timeOutId = useRef();
  const debounceShowDropDown = debounce(() => {
    showSubCategoriesDropDown(true);
  }, 500);

  const debounceHideDropDown = debounce(() => {
    showSubCategoriesDropDown(false);
  }, 500);

  function debounceDropDownShowCustom(category) {
    timeOutId.current = setTimeout(() => {
      setCategoryForSubCategory(category);
      showSubCategoriesDropDown(true);
    }, 300);
  }

  return (
    <nav
      onMouseEnter={() => {
        setIsHoverCatalog(true);
        setCatalogButtonStyle('catalog_button_partly_hovered');
      }}
      onMouseLeave={() => {
        setIsHoverCatalog(false);
        setCatalogButtonStyle('catalog_button');
      }}
      className={classes.dropdown}
    >
      <button
        type='button'
        className={classes.backButton}
        onClick={() => {
          showDropDownPanel(false);
          setCurrentOuterCategory('');
        }}
        onMouseEnter={() => setArrowColor('#ee763c')}
        onMouseLeave={() => setArrowColor('white')}
      >
        <IconLeftArrow size={25} color={arrowColor} />
      </button>

      {subCategoriesDropDown && ( // subCategoriesDropDown
        <SubCategoriesDropDown
          setCatalogButtonStyle={setCatalogButtonStyle}
          debounceShowDropDown={debounceShowDropDown}
          categoryForSubCategory={categoryForSubCategory}
          debounceHideDropDown={debounceHideDropDown}
          hideDropDownMenu={hideDropDownMenu}
        />
      )}

      {Role === 'ADMIN' ? (
        <Suspense fallback={<LoadingPage styleName='spinnerContainerDropDown' />}>
          <DropDownPanelAdmin
            classes={classes}
            timeOutId={timeOutId}
            setCurrentOuterCategory={setCurrentOuterCategory}
            debounceHideDropDown={debounceHideDropDown}
            debounceDropDownShowCustom={debounceDropDownShowCustom}
            currentOuterCategory={currentOuterCategory}
            categoriesList={categoriesList}
            showChangeCategorySectionForm={showChangeCategorySectionForm}
            showFormAddCategory={showFormAddCategory}
            showAskFullDeletionForm={showAskFullDeletionForm}
            setCategoryTarget={setCategoryTarget}
            showAddSubCategoryForm={showAddSubCategoryForm}
          />
        </Suspense>
      ) : (
        categoriesList &&
        categoriesList.map((category) =>
          hasSubCategories(category, catalogs) ? (
            <div
              className={classes.link_wrapper}
              itemScope
              itemType='https://schema.org/Product'
              onMouseEnter={() => {
                debounceDropDownShowCustom(category);
                debounceHideDropDown.cancel();
              }}
              onMouseLeave={() => {
                clearTimeout(timeOutId.current);
                debounceHideDropDown();
              }}
              key={category}
            >
              <Link
                key={category}
                itemProp='url'
                to={`outercategory/${currentOuterCategory}/${category}`}
                className={classes.link_text}
                onClick={() => {
                  setIsHoverCatalog(false);
                  setCatalogButtonStyle('catalog_button');
                }}
              >
                <span itemProp='name'>{t(`category:${category}`)}</span>
              </Link>
            </div>
          ) : (
            <div
              itemScope
              itemType='https://schema.org/Product'
              className={classes.link_wrapper}
              key={category}
            >
              <Link
                key={category}
                itemProp='url'
                to={`outercategory/${currentOuterCategory}/${category}/allgoods`}
                className={classes.link_text}
                onClick={() => {
                  setIsHoverCatalog(false);
                  setCatalogButtonStyle('catalog_button');
                }}
              >
                <span itemProp='name'>{t(`category:${category}`)}</span>
              </Link>
            </div>
          ),
        )
      )}
    </nav>
  );
}
