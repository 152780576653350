// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.F9rf_JpZnfo95hTu9ayX .SvaD97YM1aPQRkzJ4mAA{display:flex;padding-left:15px;padding-right:5px;padding-top:10px;justify-content:space-between;font-size:18px}.F9rf_JpZnfo95hTu9ayX .SvaD97YM1aPQRkzJ4mAA a{color:ivory;text-decoration:none}.F9rf_JpZnfo95hTu9ayX .SvaD97YM1aPQRkzJ4mAA .t8JxLn5_nNrbEpnhBIrp{border:none;background:none}.F9rf_JpZnfo95hTu9ayX .fyfp8x2l_1OZLj5aR9tZ{display:flex;padding-left:15px;padding-right:5px;padding-top:10px;justify-content:space-between;font-size:18px}.F9rf_JpZnfo95hTu9ayX .fyfp8x2l_1OZLj5aR9tZ a{color:ivory;text-decoration:none}`, "",{"version":3,"sources":["webpack://./src/components/NavBarPanel/DropDownPanel/SmallOuterCategoriesDropDown/NoOuterCategorySmallContainer/NoOuterCategorySmallContainer.module.scss"],"names":[],"mappings":"AACE,4CACE,YAAA,CACA,iBAAA,CACA,iBAAA,CACA,gBAAA,CACA,6BAAA,CACA,cAAA,CAEA,8CACE,WAAA,CACA,oBAAA,CAGF,kEACE,WAAA,CACA,eAAA,CAIJ,4CACE,YAAA,CACA,iBAAA,CACA,iBAAA,CACA,gBAAA,CACA,6BAAA,CACA,cAAA,CAEA,8CACE,WAAA,CACA,oBAAA","sourcesContent":[".NoOuterCategorySmallContainer {\n  .catalogWithSubcategories {\n    display: flex;\n    padding-left: 15px;\n    padding-right: 5px;\n    padding-top: 10px;\n    justify-content: space-between;\n    font-size: 18px;\n\n    a {\n      color: rgb(255, 255, 240);\n      text-decoration: none;\n    }\n\n    .right_arrow_button {\n      border: none;\n      background: none;\n    }\n  }\n\n  .noSubcategories {\n    display: flex;\n    padding-left: 15px;\n    padding-right: 5px;\n    padding-top: 10px;\n    justify-content: space-between;\n    font-size: 18px;\n\n    a {\n      color: rgb(255, 255, 240);\n      text-decoration: none;\n    }\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NoOuterCategorySmallContainer": `F9rf_JpZnfo95hTu9ayX`,
	"catalogWithSubcategories": `SvaD97YM1aPQRkzJ4mAA`,
	"right_arrow_button": `t8JxLn5_nNrbEpnhBIrp`,
	"noSubcategories": `fyfp8x2l_1OZLj5aR9tZ`
};
export default ___CSS_LOADER_EXPORT___;
