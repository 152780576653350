import { WEB_URL } from '../constants/webUrl';

export const schemaProductItemList = (title, type, baseUrl, items, keywords, breadcrumbLinks, breadcrumbTitles) => {
    function getItemList() {
        let list = [];
        let breadcrumbList = [];
        let url = `${WEB_URL}`;

        if(breadcrumbLinks && breadcrumbLinks.length) {
            let breadCrumbItem = {};

            breadcrumbLinks.forEach((link, i) => {
          
                url = link === '/' ? WEB_URL : `${WEB_URL}${link}`;
                breadCrumbItem = {
                    "@type": "ListItem",
                    "position": i+1,
                    "item": { 
                        "name": breadcrumbTitles[i] ? breadcrumbTitles[i] : '',
                        "url": url, 
                    },
                }
                breadcrumbList.push(breadCrumbItem)
            })
         }

        if(items && items.length) {
            if(type === 'catalogs') {
                items.forEach((catalog, i) => {
                    list.push({
                        "@type": "ListItem",
                        "name": catalog.title,
                        "position": i+1,
                        "url":`${WEB_URL}${baseUrl}/${catalog.link}`,
                        "image": catalog.previewImage,
                    })
                })
            }
    
            if(type === 'goods') {
                items.forEach((good, i) => {
                    list.push({
                        "@type": "Product",
                        "name": good.title,
                        "image": good.mainImage,
                        "url": `${WEB_URL}${baseUrl}/${good._id}`,
                        "offers": {
                            "@type": "Offer",
                            "name": good.title,
                            "priceCurrency": "UAH",
                            "price": good.price,
                        }
                    })
                })
            }
        }

        return {list, breadcrumbList};
    }
    
    let { list, breadcrumbList } = getItemList();

    let schema = {
        "@context": "https://schema.org",
            
            "@type": "CollectionPage",
            "name": title,
            "url": baseUrl ? `${WEB_URL}${baseUrl}` : `${WEB_URL}`,
            "description": keywords,
            "breadcrumb": {
                "@type": "BreadcrumbList",
                "itemListElement": breadcrumbList
            },

            "mainEntity": {       
                "@type": "ItemList",
                "numberOfItems": items.length,
                "name": title,
                "itemListElement": list
            },

            "provider": {
                "@type": "Organization",
                "@id": "https://www.wooolama.com/#organization"
            }
    }
    return JSON.stringify(schema);
 }