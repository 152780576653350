import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useWindowDimensions } from '../../helpers/useWindowDimension';
import { DropDownOuterPanel } from './DropDownPanel/DropDownOuterPanel/DropDownOuterPanel';
import { SmallDropDownContainer } from './SmallDropDownContainer/SmallDropDownContainer';
import { DropDownLogout } from '../DropDownLogout/DropDownLogout';
import { getCategoriesListAC } from '../../store/Goods/GoodsAC';
import { getCatalogsAC } from '../../store/Catalog/CatalogAC';
import { removeFullCategory } from '../../services/Categories/removeAllSectionsByCategory';
import { removeOuterCategory } from '../../services/Categories/removeOuterCategory';
import { FormAskForCallBack } from './FormAskForCallBack/FormAskForCallBack';
import { SearchDropDown } from '../Search/SearchDropDown';
import { AddingToShoppingCardMod } from './AddingToShoppingCardMod/AddingToShoppingCardMod';
import { ShoppingCardPreview } from '../ShoppingCard/ShoppingCardPreview';
import { ImageComponent } from '../ImageComponent/ImageComponent';
import { Bottom } from '../Bottom/Bottom';
import { Auth } from '../Auth/Auth';
import { SoshialMediaIconsContainerPureSVG } from '../SoshialMediaIconsContainer/SoshialMediaIconsContainerPureSVG';
import { IconSearch } from '../Icons/IconSearch';
import { IconLoginPerson } from '../Icons/IconLoginPerson';
import { IconOrderBag } from '../Icons/IconOrderBag';
import { IconCart } from '../Icons/IconCart';
import { IconBurger } from '../Icons/IconBurger';
import { IconChevronDown } from '../Icons/IconChevronDown';
import { LoadingPage } from '../LoadingPage/LoadingPage';
import { Alert } from '../Alert/Alert';
import mainLogoSmall from '../../assets/logoYarnBack500.webp';

import classes from './NavBarPanel.module.scss';

const ChangeCategorySectionForm = lazy(() =>
  import('./ChangeCategorySectionForm/ChangeCategorySectionForm').then((module) => ({
    default: module.ChangeCategorySectionForm,
  })),
);

const AskFullDeletionForm = lazy(() =>
  import('./DropDownPanel/AskFullDeletion/AskFullDeletionForm').then((module) => ({
    default: module.AskFullDeletionForm,
  })),
);

const FormAddOuterCategory = lazy(() =>
  import('./FormAddOuterCategory/FormAddOuterCategory').then((module) => ({
    default: module.FormAddOuterCategory,
  })),
);

const FormAddCategory = lazy(() =>
  import('./FormAddCategory/FormAddCategory').then((module) => ({
    default: module.FormAddCategory,
  })),
);

const ChangeCatalogPageForm = lazy(() =>
  import('../CatalogPage/AddCatalogPageForm/ChangeCatalogPageForm').then((module) => ({
    default: module.ChangeCatalogPageForm,
  })),
);

const FormAddSubCategory = lazy(() =>
  import('../CatalogPage/FormAddSubCategory/FormAddSubCategory').then((module) => ({
    default: module.FormAddSubCategory,
  })),
);

const FormChangeOuterCategory = lazy(() => import('./FormChangeOuterCategory/FormChangeOuterCategory'));

export function NavbarPanel({
  stateForRerender,
  setOrdersQuantity,
  ordersQuantity,
  goodsInShoppingCardQuantity,
  makeMainContentUnscrollable,
  makeMainContentScrollable,
  authTableState,
  setAuthTableState,
  shoppingCardPreview,
  setShoppingCardPreview,
  setStateToRerenderBuyButton,
  formAddCatalogPageShown,
  formAddSubCategoryShown,
  showAddCatalogForm,
  showAddSubCategoryForm,
  currentSubCategoryId,
  searchedGoods,
  searchDropDownShown,
  setSearchDropDownShown,
  showSmallSearchInput,
  handleSearchChange,
  categoryTarget,
  setCategoryTarget,
  isFormChangeOuterCategoryShown,
  setFormChangeOuterCategory,
  currentOuterCategory,
  setCurrentOuterCategory,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { width } = useWindowDimensions();
  const currentLanguageStore = localStorage.getItem('language');
  const currentLanguage = currentLanguageStore || 'ua';

  const { acessToken, name, shoppingCards } = useSelector((state) => state.UserReducer);
  const { categoriesList: outerCategoriesList } = useSelector((state) => state.GoodsReducer);
  const { catalogs } = useSelector((state) => state.CatalogReducer);
  const { idToAddGoods } = useSelector((state) => state.ShoppingCardsReducer);

  const [chevronDownColor, setChevronDownColor] = useState('white');
  const [catalogButtonStyle, setCatalogButtonStyle] = useState('catalog_button');
  const [deletingOuterCategoryStatus, setDeletingOuterCategoryStatus] = useState(false);

  const [isHoverCatalog, setIsHoverCatalog] = useState(false);
  const [isSmallDropDownShown, showSmallDropDown] = useState(false);
  const [isHoverLogout, setIsHoverLogout] = useState(false);
  const [isFormAddOuterCategoryShown, setFormAddOuterCategory] = useState(false);
  const [isFormAddCategoryShown, setFormAddCategory] = useState(false);
  const [isCallBackFormShown, showCallBackForm] = useState(false);
  const [langButtonState, setLangButtonState] = useState(currentLanguage);
  const [isAskFullDeletionFormShown, setAskFullDeletionForm] = useState(false);
  const [categorySectionChangeForm, setChangeCategorySectionForm] = useState(false);
  const [iconSize, setIconSize] = useState(40);
  const [bigScreen, setBigScreen] = useState(true);
  const [smallSizeMenu, setSmallSizeMenu] = useState(false);
  const [isSitemapCreated, setSiteMap] = useState('');

  useEffect(() => {
    if (isSitemapCreated === false) return;

    setTimeout(() => {
      setSiteMap('');
    }, [4000]);
  }, [isSitemapCreated]);

  useEffect(() => {
    if (acessToken) {
      const filteredShoppingCards = shoppingCards.filter(
        (shoppingCard) =>
          shoppingCard.status === 'UNCONFIRMED' ||
          shoppingCard.status === 'RESERVED' ||
          shoppingCard.status === 'CONFIRMED' ||
          shoppingCard.status === 'SENT',
      );
      filteredShoppingCards.length ? setOrdersQuantity(filteredShoppingCards.length) : setOrdersQuantity('');
    }

    if (!acessToken) {
      const orders = JSON.parse(localStorage.getItem('orders'));

      const filteredOrders =
        orders &&
        orders.filter(
          (order) =>
            order.orderStatus === 'UNCONFIRMED' ||
            order.orderStatus === 'RESERVED' ||
            order.orderStatus === 'CONFIRMED' ||
            order.orderStatus === 'SENT',
        );

      orders && orders.length > 0 ? setOrdersQuantity(filteredOrders.length) : setOrdersQuantity('');
    }
  }, [shoppingCards, acessToken, stateForRerender]);

  useEffect(() => {
    if (width <= 799) {
      setSmallSizeMenu(true);
    }

    if (width >= 799) {
      setSmallSizeMenu(false);
    }

    if (width <= 300) {
      setIconSize(() => 20);
      setBigScreen(false);
      return;
    }
    if (width <= 400) {
      setIconSize(25);
      setBigScreen(false);
      return;
    }
    if (width <= 600) {
      setIconSize(() => 35);
      setBigScreen(false);
      return;
    }

    if (width > 600) {
      setIconSize(() => 40);
      setBigScreen(true);
    }
  }, [width]);

  function showAskFullDeletionForm() {
    setAskFullDeletionForm(true);
  }

  function hideAskFullDeletionForm() {
    setAskFullDeletionForm(false);
    setCategoryTarget('');
  }

  function hideSmallDropDownContainer() {
    showSmallDropDown(false);
  }

  function getNewCategoriesList() {
    const outerCategoryObj = outerCategoriesList.find(
      (outerCategoryObj) => outerCategoryObj.outerCategory === currentOuterCategory,
    );
    return outerCategoryObj.categories.filter((category) => category !== categoryTarget);
  }

  async function handleDeleteSection() {
    try {
      const chosenCatalog = catalogs.find((catalog) => catalog.category === categoryTarget);
      const catalogId = chosenCatalog && chosenCatalog._id;
      const newCategoryList = getNewCategoriesList();

      deletingOuterCategoryStatus
        ? await removeOuterCategory({ outerCategory: currentOuterCategory })
        : await removeFullCategory({
            currentOuterCategory,
            category: categoryTarget,
            newCategoryList,
            token: acessToken,
            catalogId,
          });

      dispatch(getCategoriesListAC());
      dispatch(getCatalogsAC());
    } catch (error) {
      console.log('Error catched by handleDeleteSection', error);
    }
  }

  function showChangeCategorySectionForm() {
    setChangeCategorySectionForm((prev) => !prev);
  }

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  function showFormAddOuterCategory() {
    setFormAddOuterCategory((prev) => !prev);
  }

  function showFormAddCategory() {
    setFormAddCategory((prev) => !prev);
  }

  function showDropDownMenu() {
    setIsHoverCatalog(true);
  }

  function hideDropDownMenu(event) {
    if (event.className === 'dropdown') return;
    setIsHoverCatalog(false);
  }

  function showDropDownLogout() {
    setIsHoverLogout(true);
  }

  function hideDropDownLogout(event) {
    if (event.className === 'DropDownLogout') {
      return;
    }
    setIsHoverLogout(false);
  }

  return (
    <>
      <div className={classes.abovenavbar}>
        {!smallSizeMenu && <SoshialMediaIconsContainerPureSVG />}
        <div className={classes.language_buttons_container}>
          <button
            type='button'
            className={langButtonState === 'ua' ? classes.ua_lang_button_choosen : classes.ua_lang_button}
            onClick={() => {
              setLangButtonState(() => 'ua');
              changeLanguage('ua');
              localStorage.setItem('language', 'ua');
              window.location.reload();
            }}
          >
            Укр
          </button>
          <button
            type='button'
            className={langButtonState === 'en' ? classes.en_lang_button_choosen : classes.en_lang_button}
            onClick={() => {
              setLangButtonState('en');
              changeLanguage('en');
              localStorage.setItem('language', 'en');
              window.location.reload();
            }}
          >
            Eng
          </button>
        </div>
        {width >= 799 && (
          <div itemScope itemType='https://schema.org/Organization' className={classes.phoneNumbers}>
            <span itemProp='telephone'>+380955025286</span>
            <span itemProp='telephone'>+380957729338</span>
          </div>
        )}
      </div>
      <div className={classes.middle_navbar}>
        <Link to='/' className={classes.logo_wrapper}>
          <ImageComponent
            src={mainLogoSmall}
            alt='wooolama інтернет-магазин пряжі'
            width='180px'
            height='140px'
            customStyleHash='round'
            customStyleImg='logoImage'
            blurHash='|BHyLDsC00%1K$R5IpK*]NCmWB#lvz]$kXNeShKj5*b^$zIpw0%1krrWbIm+WBl9GHKj#Rn3WB#7#%jFXTslK6JVs8o~KP+]NaxD,oI;NGTJT0i_t6wHo}jGibT0wvi^R+NHfk%LaeRPbbslrrW=X8bGXnOZs:i_w]$Ln$'
          />
        </Link>
        <div className={classes.search_container}>
          {!bigScreen ? (
            <div
              className={classes.search_sign_container}
              onClick={() => {
                showSmallSearchInput();
              }}
            >
              <IconSearch size={30} />
            </div>
          ) : (
            <div className={classes.search}>
              <input
                placeholder={t('static:search')}
                aria-label='Search'
                aria-describedby='basic-addon1'
                id='search'
                className={classes.searchInput}
                onFocus={(e) => {
                  e.target.value && searchedGoods.length && setSearchDropDownShown(true);
                }}
                onChange={(e) => {
                  handleSearchChange(e.target.value, setSearchDropDownShown);
                }}
              />
              <div
                className={classes.search_sign_container}
                onClick={() => {
                  setSearchDropDownShown(false);
                  searchedGoods.length && navigate('/goods/search');
                }}
              >
                <IconSearch size={30} />
              </div>
            </div>
          )}
          {searchDropDownShown && (
            <SearchDropDown
              goods={searchedGoods}
              styleClassName='searchDropDownContainer'
              nothingFoundButtonStyle='nothingFoundButton'
            />
          )}
        </div>

        <div className={classes.additional_info}>
          <div className={classes.card_and_log_icons}>
            {width > 799 && (
              <div className={classes.call_back_container}>
                <button
                  type='button'
                  className={classes.callback_button}
                  onClick={() => {
                    showCallBackForm((prev) => !prev);
                    makeMainContentUnscrollable();
                  }}
                >
                  {t('static:callback')}
                </button>
              </div>
            )}
            {width > 799 && (
              <div className={classes.login_button_container}>
                {acessToken ? (
                  <Link
                    to='/userpage'
                    onMouseEnter={showDropDownLogout}
                    onMouseLeave={(e) => hideDropDownLogout(e)}
                    className={classes.login_link}
                  >
                    {name}
                  </Link>
                ) : (
                  <button
                    type='button'
                    size={iconSize}
                    onClick={() => {
                      setAuthTableState((prev) => !prev);
                      makeMainContentUnscrollable();
                    }}
                    className={classes.login_button}
                  >
                    <IconLoginPerson size={iconSize} />
                  </button>
                )}
                {isHoverLogout && ( // isHoverLogout
                  <DropDownLogout
                    showDropDownLogout={showDropDownLogout}
                    hideDropDownLogout={hideDropDownLogout}
                  />
                )}
              </div>
            )}
            {idToAddGoods ? (
              <AddingToShoppingCardMod />
            ) : (
              <>
                <div className={classes.orders_container}>
                  <button
                    type='button'
                    className={classes.shoppingBagButton}
                    onClick={() => {
                      acessToken ? navigate('/userpage') : navigate('/unregistereduserpage');
                    }}
                  >
                    <IconOrderBag size={iconSize} />
                  </button>

                  {ordersQuantity && ordersQuantity > 0 && (
                    <div
                      className={classes.goodsCountBadge}
                      onClick={() => {
                        acessToken ? navigate('/userpage') : navigate('/unregistereduserpage');
                      }}
                    >
                      {ordersQuantity}
                    </div>
                  )}
                </div>
                <div className={classes.shopping_card_container}>
                  <button
                    type='button'
                    className={classes.shoppingCardButton}
                    onClick={() => {
                      setShoppingCardPreview(true);
                      makeMainContentUnscrollable();
                    }}
                  >
                    <IconCart size={iconSize} />
                  </button>

                  {goodsInShoppingCardQuantity && goodsInShoppingCardQuantity !== 0 && (
                    <div
                      text='dark'
                      className={classes.goodsCountBadge}
                      onClick={() => {
                        setShoppingCardPreview(true);
                        makeMainContentUnscrollable();
                      }}
                    >
                      {goodsInShoppingCardQuantity}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {smallSizeMenu ? (
        <div className={classes.burgerMenu}>
          <button
            type='button'
            className={classes.burgerMenuButton}
            onClick={() => {
              showSmallDropDown(true);
              makeMainContentUnscrollable();
            }}
          >
            <IconBurger />
          </button>
        </div>
      ) : (
        <div className={classes.navbar}>
          <div className={classes.emptyLeftDiv}></div>
          <div className={classes.catalogButtonContainer}>
            <button
              type='button'
              onMouseEnter={() => {
                showDropDownMenu();
                setChevronDownColor('#ee763c');
                setCatalogButtonStyle('catalog_button_hovered');
              }}
              onMouseLeave={(e) => {
                hideDropDownMenu(e);
                setChevronDownColor('white');
                setCatalogButtonStyle('catalog_button');
              }}
              className={classes[catalogButtonStyle]}
            >
              <span className={classes.catalogWord}>{t('static:catalog')}</span>
              <span className={classes.chevronDownWrapper}>
                <IconChevronDown size={20} color={chevronDownColor} />
              </span>
            </button>
          </div>
          <div className={classes.navbarContainer}>
            <div className={classes.navigationLinks}>
              <Link to='https://www.instagram.com/larisa__lara13/'>Instagram</Link>
              <Link to='https://www.facebook.com/groups/550137906752470'>Facebook</Link>
              <Link to='/goods/discountgoods'>{t('static:discountGoods')}</Link>
              <Link to='paymentanddelivery'>{t('static:paymentsAndDelivery')}</Link>
              <Link to='contacts'>{t('static:contacts')}</Link>
            </div>
          </div>
        </div>
      )}
      {isSitemapCreated && <Alert variant='success_absolute'> {isSitemapCreated} </Alert>}
      {authTableState && (
        <Auth setAuthTableState={setAuthTableState} makeMainContentScrollable={makeMainContentScrollable} />
      )}
      {isSmallDropDownShown && ( // isSmallDropDownShown
        <div
          className={classes.smallDropDownContainerBackground}
          onClick={(e) => {
            e.stopPropagation();
            hideSmallDropDownContainer((prev) => !prev);
            makeMainContentScrollable();
          }}
        >
          <SmallDropDownContainer
            makeMainContentUnscrollable={makeMainContentUnscrollable}
            makeMainContentScrollable={makeMainContentScrollable}
            setAuthTableState={setAuthTableState}
            showCallBackForm={showCallBackForm}
            langButtonState={langButtonState}
            setLangButtonState={setLangButtonState}
            changeLanguage={changeLanguage}
            hideSmallDropDownContainer={hideSmallDropDownContainer}
          />
        </div>
      )}
      {isHoverCatalog && ( // isHoverCatalog
        <DropDownOuterPanel
          setCatalogButtonStyle={setCatalogButtonStyle}
          setDeletingOuterCategoryStatus={setDeletingOuterCategoryStatus}
          hideDropDownMenu={hideDropDownMenu}
          setIsHoverCatalog={setIsHoverCatalog}
          showFormAddOuterCategory={showFormAddOuterCategory}
          showFormAddCategory={showFormAddCategory}
          showChangeCategorySectionForm={showChangeCategorySectionForm}
          showAskFullDeletionForm={showAskFullDeletionForm}
          setCategoryTarget={setCategoryTarget}
          showAddCatalogForm={showAddCatalogForm}
          showAddSubCategoryForm={showAddSubCategoryForm}
          setCurrentOuterCategory={setCurrentOuterCategory}
          currentOuterCategory={currentOuterCategory}
          setFormChangeOuterCategory={setFormChangeOuterCategory}
          setSiteMap={setSiteMap}
        />
      )}
      {shoppingCardPreview && (
        <ShoppingCardPreview
          makeMainContentScrollable={makeMainContentScrollable}
          setShoppingCardPreview={setShoppingCardPreview}
          customStyle='shoppingCardGeneral'
          setStateToRerenderBuyButton={setStateToRerenderBuyButton}
        />
      )}
      {isFormAddOuterCategoryShown && (
        <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
          <FormAddOuterCategory showFormAddOuterCategory={showFormAddOuterCategory} />
        </Suspense>
      )}
      {isFormChangeOuterCategoryShown && (
        <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
          <FormChangeOuterCategory
            setFormChangeOuterCategory={setFormChangeOuterCategory}
            currentOuterCategory={currentOuterCategory}
          />
        </Suspense>
      )}
      {isFormAddCategoryShown && (
        <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
          <FormAddCategory
            outerCategory={currentOuterCategory}
            setCurrentOuterCategory={setCurrentOuterCategory}
            showFormAddCategory={showFormAddCategory}
          />
        </Suspense>
      )}
      {isCallBackFormShown && (
        <FormAskForCallBack
          showCallBackForm={showCallBackForm}
          makeMainContentScrollable={makeMainContentScrollable}
        />
      )}
      {categorySectionChangeForm && (
        <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
          <ChangeCategorySectionForm
            categoryTarget={categoryTarget}
            showChangeCategorySectionForm={showChangeCategorySectionForm}
            currentOuterCategory={currentOuterCategory}
          />
        </Suspense>
      )}
      {formAddCatalogPageShown && (
        <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
          <ChangeCatalogPageForm showAddCatalogForm={showAddCatalogForm} categoryTarget={categoryTarget} />
        </Suspense>
      )}
      {formAddSubCategoryShown && (
        <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
          <FormAddSubCategory
            category={categoryTarget}
            currentSubCategoryId={currentSubCategoryId}
            showAddSubCategoryForm={showAddSubCategoryForm}
          />
        </Suspense>
      )}
      {isAskFullDeletionFormShown && (
        <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
          <AskFullDeletionForm
            handleDeleteSection={handleDeleteSection}
            hideAskFullDeletionForm={hideAskFullDeletionForm}
            setDeletingOuterCategoryStatus={setDeletingOuterCategoryStatus}
          />
        </Suspense>
      )}
      <Outlet />
      <Bottom />
    </>
  );
}
