

export default function getGoodLink(outerCategoriesList, good) {

  let link = '';

    let outerCategory = null;

    for(let outerCategoryObj of outerCategoriesList) {
      if(outerCategoryObj.categories.includes(good.category)) {
        outerCategory = outerCategoryObj.outerCategory;
        break;
      }
    }
    
    if (outerCategory === 'noOuterCategory') {
      if (good.subcategory === 'allgoods') {
        link = `/goods/${good.category}/${good._id}`;
        return link;
      }
      if (good.subcategory) {
        link = `/catalog/${good.category}/${good.subcategory}/${good._id}`;
        return link;
      }
    }

    if (outerCategory) {
      if (good.subcategory === 'allgoods') {
        link = `/outercategory/${outerCategory}/${good.category}/allgoods/${good._id}`;
        return link;
      }
      if (good.subcategory) {
        link = `/outercategory/${outerCategory}/${good.category}/${good.subcategory}/${good._id}`;
        return link;
      }
    }
  
  return link;
}

// export getGoodLink; // ES Module Export

// if (typeof module !== "undefined" && module.exports) {
//   module.exports = getGoodLink; // CommonJS (Node.js)
// }