import React, { useEffect, useState, useRef, lazy } from 'react';
import debounce from 'lodash/debounce';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SubCategoriesDropDown } from '../../SubCategoriesDropDown/SubCategoriesDropDown';
import { hasSubCategories } from '../../../../helpers/hasSubcategories';

import classes from './NoOuterCategoryContainer.module.scss';

const NoOuterCategoryContainerAdmin = lazy(() =>
  import('./NoOuterCategoryContainerAdmin').then((module) => ({
    default: module.NoOuterCategoryContainerAdmin,
  })),
);

export function NoOuterCategoriesContainer({
  setIsHoverCatalog,
  setCatalogButtonStyle,
  hideDropDownMenu,
  showAddSubCategoryForm,
  showAskFullDeletionForm,
  showChangeCategorySectionForm,
  setCategoryTarget,
  setCurrentOuterCategory,
}) {
  const { categoriesList } = useSelector((state) => state.GoodsReducer);
  const { catalogs } = useSelector((state) => state.CatalogReducer);
  const { Role } = useSelector((state) => state.UserReducer);

  const [subCategoriesDropDown, showSubCategoriesDropDown] = useState(false);
  const [categoryForSubCategory, setCategoryForSubCategory] = useState('');
  const [noOuterCategoriesList, setNoOuterCategoriesList] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    const noOuterCategoryObj = categoriesList.find(
      (outerCategoryObj) => outerCategoryObj.outerCategory === 'noOuterCategory',
    );
    noOuterCategoryObj && setNoOuterCategoriesList(noOuterCategoryObj.categories);
  }, [categoriesList]);

  const timeOutId = useRef();
  const debounceShowDropDown = debounce(() => {
    showSubCategoriesDropDown(true);
  }, 500);

  const debounceHideDropDown = debounce(() => {
    showSubCategoriesDropDown(false);
  }, 500);

  function debounceDropDownShowCustom(category) {
    timeOutId.current = setTimeout(() => {
      setCategoryForSubCategory(category);
      showSubCategoriesDropDown(true);
    }, 300);
  }
  return (
    <>
      {subCategoriesDropDown && ( // subCategoriesDropDown
        <SubCategoriesDropDown
          debounceShowDropDown={debounceShowDropDown}
          categoryForSubCategory={categoryForSubCategory}
          debounceHideDropDown={debounceHideDropDown}
          hideDropDownMenu={hideDropDownMenu}
        />
      )}
      {Role === 'ADMIN' ? (
        <NoOuterCategoryContainerAdmin
          classes={classes}
          showAddSubCategoryForm={showAddSubCategoryForm}
          showAskFullDeletionForm={showAskFullDeletionForm}
          showChangeCategorySectionForm={showChangeCategorySectionForm}
          setCategoryTarget={setCategoryTarget}
          setCurrentOuterCategory={setCurrentOuterCategory}
          noOuterCategoriesList={noOuterCategoriesList}
          hasSubCategories={hasSubCategories}
          debounceDropDownShowCustom={debounceDropDownShowCustom}
          debounceHideDropDown={debounceHideDropDown}
          timeOutId={timeOutId}
          setIsHoverCatalog={setIsHoverCatalog}
          setCatalogButtonStyle={setCatalogButtonStyle}
        />
      ) : (
        <div itemScope itemType='https://schema.org/Product' className={classes.noOuterCategory}>
          {noOuterCategoriesList.map((category) => (
            <div
              className={classes.link_wrapper_container}
              onMouseEnter={() => {
                if (hasSubCategories(category, catalogs)) {
                  debounceDropDownShowCustom(category);
                  debounceHideDropDown.cancel();
                }
              }}
              onMouseLeave={() => {
                clearTimeout(timeOutId.current);
                debounceHideDropDown();
              }}
              key={category}
            >
              {hasSubCategories(category, catalogs) ? (
                <Link
                  key={category}
                  to={`catalog/${category}`}
                  className={classes.link_text}
                  itemProp='url'
                  onClick={() => {
                    setIsHoverCatalog(false);
                    setCatalogButtonStyle('catalog_button');
                  }}
                  onMouseEnter={() => {
                    debounceDropDownShowCustom(category);
                    debounceHideDropDown.cancel();
                  }}
                  onMouseLeave={() => {
                    clearTimeout(timeOutId.current);
                    debounceHideDropDown();
                  }}
                >
                  <span itemProp='name'>{t(`category:${category}`)}</span>
                </Link>
              ) : (
                <Link
                  key={category}
                  to={`goods/${category}`}
                  className={classes.link_text}
                  itemProp='url'
                  onClick={() => {
                    setIsHoverCatalog(false);
                    setCatalogButtonStyle('catalog_button');
                  }}
                >
                  <span itemProp='name'>{t(`category:${category}`)}</span>
                </Link>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
}
