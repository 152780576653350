import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getGoodsArraysAC } from '../../store/Goods/GoodsAC';
import { changeGoodSubcategory } from '../../services/Goods/changeGoodSubcategory';
import { removeGood } from '../../services/Goods/removeGood';
import { handleBuyButton } from '../../helpers/handleBuyButton';
import { useBuyButtonState } from '../../helpers/useBuyButtonState';
import getGoodLink from '../../helpers/getGoodLink';
import { IconRefresh } from '../Icons/IconRefresh';
import { IconCloseButton } from '../Icons/IconCloseButton';

import placeholder from '../../assets/placeholder.webp';

import classes from './CardItem.module.scss';

export function CardItem({
  setStateToRerenderBuyButton,
  stateToRerenderBuyButton,
  good,
  category,
  goods,
  index,
  showChangeGoodForm,
  setGoodIdChangeForm,
  currentSubCategoriesList,
  setShoppingCardPreview,
  makeMainContentUnscrollable,
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const pathname = location.pathname.split('/').slice(1);

  const dispatch = useDispatch();
  const {
    price,
    measurement,
    measurementCount,
    discount,
    newGoodState,
    popular,
    quantity,
    articul,
    _id: goodId,
    subcategory,
    mainImage,
  } = good;

  const { categoriesList: outerCategoriesList } = useSelector((state) => state.GoodsReducer);
  const { idToAddGoods } = useSelector((state) => state.ShoppingCardsReducer);
  const { Role, acessToken } = useSelector((state) => state.UserReducer);

  const [isShownDescription, setIsShownDescription] = useState(false);
  const [isGoodAvailable, setGoodAvailable] = useState(true);
  const [discountPrice, setDiscountPrice] = useState();
  const [cardImg, setCardImg] = useState();
  const [isImgLoaded, setImageLoaded] = useState(false);
  let [link, setLink] = useState('');

  const [buyButtonName, isButtonActive] = useBuyButtonState(goodId, stateToRerenderBuyButton);

  useEffect(() => {
    if (pathname[1] === 'search' || pathname[1] === 'discountgoods' || !pathname[1]) {
      setLink(getGoodLink(outerCategoriesList, good));
    }
  }, [good, outerCategoriesList]);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };

    if (good) {
      img.src = mainImage || placeholder;
      setCardImg(img.src);
    }
  }, [good]);

  useEffect(() => {
    if (discount && discount.hasDiscount && discount.amount) {
      price && setDiscountPrice(() => Math.floor(price - (price * discount.amount) / 100));
    }
  }, [discount, price]);

  useEffect(() => {
    !quantity && setGoodAvailable(false);
  }, [quantity]);

  function showDescription() {
    setIsShownDescription(true);
  }

  function hideDescription(event) {
    if (event.className === 'dropdown') return;
    setIsShownDescription(false);
  }

  async function handleRemoveGood(e, goods) {
    await removeGood(category, goodId, acessToken);
    dispatch(getGoodsArraysAC());
  }

  return (
    <div
      onMouseEnter={showDescription}
      onMouseLeave={(e) => {
        hideDescription(e);
      }}
      className={classes.card}
      key={goodId}
    >
      {Role === 'ADMIN' && (
        <div className={classes.controlPanel} key={goodId}>
          {currentSubCategoriesList && currentSubCategoriesList.length && (
            <div className={classes.form_add_subcategory_wrapper}>
              <select
                value='value'
                className={classes.form_add_subcategory}
                onChange={async (e) => {
                  function getAllGoods() {
                    dispatch(getGoodsArraysAC());
                  }

                  async function changeGoodSubcategoryAndRerender() {
                    await changeGoodSubcategory({
                      goodId,
                      subcategory: e.target.value,
                      token: acessToken,
                    });
                  }

                  await changeGoodSubcategoryAndRerender();
                  getAllGoods();
                }}
              >
                <option key='defaultValue' value={subcategory}>
                  {subcategory}
                </option>
                {currentSubCategoriesList &&
                  currentSubCategoriesList
                    .filter((item) => item.title !== subcategory)
                    .map((subcategory, subcategoryIndex) => (
                      <option value={subcategory.title} key={subcategoryIndex}>
                        {subcategory.title}
                      </option>
                    ))}
              </select>
            </div>
          )}
          <div className={classes.refresh_and_close_buttons}>
            <div
              className={classes.change_good_icon}
              onClick={() => {
                showChangeGoodForm(true);
                setGoodIdChangeForm(goodId);
              }}
            >
              <IconRefresh size={30} />
            </div>

            <button
              className={classes.closeButton}
              id={index}
              onClick={async (e) => {
                await handleRemoveGood(e, goods);
              }}
            >
              <IconCloseButton size={20} />
            </button>
          </div>
        </div>
      )}
      {isImgLoaded ? (
        <div className={classes.cardImageContainer}>
          <div className={classes.badgesContainer}>
            {discount && discount.hasDiscount && discount.amount && (
              <span className={classes.discountBadge}>Discount</span>
            )}
            {discount && discount.hasDiscount && discount.amount && (
              <span className={classes.discountAmountBadge}>{`${discount.amount}%`}</span>
            )}
            {newGoodState && <span className={classes.newGoodBadge}>New good</span>}
            {popular && <span className={classes.popularBadge}>Popular</span>}
          </div>

          <Link to={link ? `${link}` : `${goodId}`}>
            <img
              className={classes.cardImg}
              alt={t(`good_translation:${goodId}:title`)}
              title={t(`good_translation:${goodId}:title`)}
              loading='lazy'
              src={cardImg}
              width='270'
              height='274'
            />
          </Link>
        </div>
      ) : (
        <div className={classes.noImageLoadedContainer}></div>
      )}
      <div className={classes.cardBody}>
        <div className={classes.articul}>{` ${t('static:articul')} : ${articul}`}</div>
        <div className={classes.title}>{t(`good_translation:${goodId}:title`)}</div>
        <div className={classes.buttonPrice}>
          <div className={classes.price}>
            {discountPrice ? (
              <div className={classes.oldAndNewPrice}>
                <span className={classes.oldPrice}>{`${price} ${t('static:uah')}`}</span>
                <div className={classes.newPriceAndMeasurement}>
                  <span>{`${discountPrice} ${t('static:uah')}`}</span>
                  <span className={classes.measurement}>{` (${measurementCount} ${measurement})`}</span>
                </div>
              </div>
            ) : (
              <>
                <span>{`${price} ${t('static:uah')}`}</span>
                <span className={classes.measurement}>{` (${measurementCount} ${measurement})`}</span>
              </>
            )}
          </div>
          {isGoodAvailable ? (
            <button
              type='button'
              className={isButtonActive ? classes.buy_button : classes.buy_button_inactive}
              onClick={(e) => {
                handleBuyButton(
                  e,
                  isButtonActive,
                  idToAddGoods,
                  goodId,
                  category,
                  acessToken,
                  Role,
                  setStateToRerenderBuyButton,
                  setShoppingCardPreview,
                  dispatch,
                );
                buyButtonName === 'goodInShoppingCard' && makeMainContentUnscrollable();
              }}
            >
              {t(`static:${buyButtonName}`)}
            </button>
          ) : (
            <button className={classes.inactiveBuyButton}>{t(`static:${buyButtonName}`)}</button>
          )}
        </div>
        <div className={isGoodAvailable ? classes.availability : classes.notAvailable}>
          {quantity ? `${quantity} ${t('static:available')}` : `${t('static:notAvailable')}`}
        </div>
      </div>
      {isShownDescription && (
        <div className={classes.shortDescription}>
          <div>{t(`good_translation:${goodId}:shortDescription`)}</div>
        </div>
      )}
    </div>
  );
}
