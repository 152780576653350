import React from 'react';

export function IconGrid({ size, color }) {
  return (
    <svg id='iconGrid' viewBox='0 0 24 24' style={{ fill: 'none', width: size, height: size }}>
      <title>Refresh</title>
      <path
        d='M3.5 3.5H10.5V10.5H3.5V3.5Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.5 13.5H10.5V20.5H3.5V13.5Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.5 3.5H20.5V10.5H13.5V3.5Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.5 13.5H20.5V20.5H13.5V13.5Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
