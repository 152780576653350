import React, { useState, lazy, Suspense } from 'react';
import debounce from 'lodash/debounce';
import { useSelector } from 'react-redux';

import { NoOuterCategoriesContainer } from '../NoOuterCategoryContainer/NoOuterCategoryContainer';
import { OuterCategoryContainer } from './OuterCategoryContainer/OuterCategoryContainer';
import { DropDownPanel } from '../DropDownPanel';
import { SubCategoriesDropDown } from '../../SubCategoriesDropDown/SubCategoriesDropDown';
import { LoadingPage } from '../../../LoadingPage/LoadingPage';

import classes from './DropDownOuterPanel.module.scss';

const DropDownOuterPanelAdmin = lazy(() =>
  import('./DropDownOuterPanelAdmin').then((module) => ({
    default: module.DropDownOuterPanelAdmin,
  })),
);

export function DropDownOuterPanel({
  setCatalogButtonStyle,
  setDeletingOuterCategoryStatus,
  setIsHoverCatalog,
  hideDropDownMenu,
  showFormAddOuterCategory,
  showAddSubCategoryForm,
  showFormAddCategory,
  synhronizeGoods,
  setCurrentOuterCategory,
  currentOuterCategory,
  showChangeCategorySectionForm,
  setCategoryTarget,
  showAskFullDeletionForm,
  setFormChangeOuterCategory,
  setSiteMap,
}) {
  const { categoriesList } = useSelector((state) => state.GoodsReducer);
  const { Role } = useSelector((state) => state.UserReducer);

  const [subCategoriesDropDown, showSubCategoriesDropDown] = useState(false);
  const [categoryForSubCategory, setCategoryForSubCategory] = useState('');
  const [isDropDownPanelShown, showDropDownPanel] = useState(false);
  const [categoriesListDropDownPanel, setCategoriesListDropDownPanel] = useState([]);

  const debounceShowDropDown = debounce(() => {
    showSubCategoriesDropDown(true);
  }, 500);

  const debounceHideDropDown = debounce(() => {
    showSubCategoriesDropDown(false);
  }, 500);

  return isDropDownPanelShown ? ( // isDropDownPanelShown
    <DropDownPanel
      setCatalogButtonStyle={setCatalogButtonStyle}
      categoriesList={categoriesListDropDownPanel}
      setIsHoverCatalog={setIsHoverCatalog}
      showFormAddCategory={showFormAddCategory}
      showAddSubCategoryForm={showAddSubCategoryForm}
      showDropDownPanel={showDropDownPanel}
      setCurrentOuterCategory={setCurrentOuterCategory}
      setCategoryTarget={setCategoryTarget}
      showChangeCategorySectionForm={showChangeCategorySectionForm}
      showAskFullDeletionForm={showAskFullDeletionForm}
      hideDropDownMenu={hideDropDownMenu}
      currentOuterCategory={currentOuterCategory}
    />
  ) : (
    <div
      className={classes.dropDownOuterPanel}
      onMouseEnter={() => {
        setIsHoverCatalog(true);
        setCatalogButtonStyle('catalog_button_partly_hovered');
      }}
      onMouseLeave={() => {
        setIsHoverCatalog(false);
        setCatalogButtonStyle('catalog_button');
      }}
    >
      {subCategoriesDropDown && ( // subCategoriesDropDown
        <SubCategoriesDropDown
          setCatalogButtonStyle={setCatalogButtonStyle}
          debounceShowDropDown={debounceShowDropDown}
          categoryForSubCategory={categoryForSubCategory}
          debounceHideDropDown={debounceHideDropDown}
          hideDropDownMenu={hideDropDownMenu}
        />
      )}

      {Role === 'ADMIN' ? (
        <Suspense fallback={<LoadingPage styleName='spinnerContainerDropDown' />}>
          <DropDownOuterPanelAdmin
            classes={classes}
            setDeletingOuterCategoryStatus={setDeletingOuterCategoryStatus}
            showFormAddCategory={showFormAddCategory}
            showFormAddOuterCategory={showFormAddOuterCategory}
            synhronizeGoods={synhronizeGoods}
            setFormChangeOuterCategory={setFormChangeOuterCategory}
            setSiteMap={setSiteMap}
            setIsHoverCatalog={setIsHoverCatalog}
            hideDropDownMenu={hideDropDownMenu}
            showAddSubCategoryForm={showAddSubCategoryForm}
            showAskFullDeletionForm={showAskFullDeletionForm}
            showChangeCategorySectionForm={showChangeCategorySectionForm}
            setCategoryTarget={setCategoryTarget}
            setCurrentOuterCategory={setCurrentOuterCategory}
            showDropDownPanel={showDropDownPanel}
            setCategoriesListDropDownPanel={setCategoriesListDropDownPanel}
          />
        </Suspense>
      ) : (
        <nav>
          {categoriesList.map((outerCategoryObj) =>
            outerCategoryObj && outerCategoryObj.outerCategory === 'noOuterCategory' ? (
              <NoOuterCategoriesContainer
                setIsHoverCatalog={setIsHoverCatalog}
                setCatalogButtonStyle={setCatalogButtonStyle}
                hideDropDownMenu={hideDropDownMenu}
                showAddSubCategoryForm={showAddSubCategoryForm}
                showAskFullDeletionForm={showAskFullDeletionForm}
                showChangeCategorySectionForm={showChangeCategorySectionForm}
                setCategoryTarget={setCategoryTarget}
                setCurrentOuterCategory={setCurrentOuterCategory}
                key={outerCategoryObj.outerCategory}
              />
            ) : (
              <OuterCategoryContainer
                setCatalogButtonStyle={setCatalogButtonStyle}
                setIsHoverCatalog={setIsHoverCatalog}
                outerCategoryObj={outerCategoryObj}
                showDropDownPanel={showDropDownPanel}
                setCategoriesListDropDownPanel={setCategoriesListDropDownPanel}
                setCurrentOuterCategory={setCurrentOuterCategory}
                key={outerCategoryObj.outerCategory}
              />
            ),
          )}
        </nav>
      )}
    </div>
  );
}
