import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getCatalogsAC } from '../../../store/Catalog/CatalogAC';
import { removeSubCategory } from '../../../services/Catalog/removeSubCategory';
import { IconCloseButton } from '../../Icons/IconCloseButton';
import placeholder from '../../../assets/placeholder.webp';

import classes from './CatalogCard.module.scss';

export function CatalogCard({ catalog, catalogId }) {
  const [link, setLink] = useState('');
  const [title, setTitle] = useState('');
  const [catalogImg, setCatalogImg] = useState();
  const [isImgLoaded, setImageLoaded] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { category, outercategory } = useParams();
  const { Role, acessToken } = useSelector((state) => state.UserReducer);

  async function handleCloseButton() {
    await removeSubCategory({ subCategoryId: catalog._id, catalogId, acessToken });
    dispatch(getCatalogsAC());
  }

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };

    if (catalog._id) {
      img.src = catalog.previewImage || placeholder;
      setCatalogImg(img.src);
    }
  }, [catalog.previewImage]);

  useEffect(() => {
    if (catalog.outerCategory) {
      setLink(`outercategory/${catalog.outerCategory}`);
      setTitle(t(`outerCategory:${catalog.outerCategory}:title`));
      return;
    }

    if (outercategory) {
      if (catalogId) {
        if (catalog.allGoods === 'allgoods') {
          setLink(`/outercategory/${outercategory}/${catalog.category}/allgoods`);
          setTitle(t('static:allGoodsOfCatalog'));
        } else {
          setLink(
            `/outercategory/${outercategory}/${category}/${catalog ? catalog.title.split(' ').join('') : ''}`,
          );
          setTitle(t(`subcategories:${catalog._id}:title`));
        }
      } else {
        setLink(
          catalog.subCategories && catalog.subCategories.length
            ? `/outercategory/${outercategory}/${catalog.category}`
            : `/outercategory/${outercategory}/${catalog.category}/allgoods`,
        );
        setTitle(t(`category:${catalog.category}`));
      }
    }

    if (!outercategory) {
      if (catalogId) {
        if (catalog.allGoods) {
          setLink(`/catalog/${category}/allgoods`);
          setTitle(t('static:allGoodsOfCatalog'));
        } else {
          setLink(`/catalog/${category}/${catalog.title.split(' ').join('')}`);
          setTitle(t(`subcategories:${catalog._id}:title`));
        }
      } else {
        setLink(
          catalog && catalog.subCategories && catalog.subCategories.length
            ? `/catalog/${catalog.category}`
            : `/goods/${catalog.category}`,
        );
        setTitle(t(`category:${catalog.category}`));
      }
    }
  }, []);

  return (
    <div className={classes.catalog_card} key={catalog.category}>
      {Role === 'ADMIN' && catalogId && (
        <button className={classes.close_button} onClick={handleCloseButton}>
          <IconCloseButton size={20} />
        </button>
      )}
      {isImgLoaded ? (
        <div className={classes.imageWrapper}>
          <Link to={link}>
            <img
              className={classes.cardImg}
              loading='lazy'
              width='260'
              height='369'
              src={catalogImg}
              title={title}
              alt={title}
            />
          </Link>
        </div>
      ) : (
        <div className={classes.noPlaceholderLoaded} />
      )}
      <div className={classes.card_body}>{title}</div>
    </div>
  );
}
