import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import wooLamaLogo from '../../assets/wooLamaLogo.png';
import classes from './Bottom.module.scss';

export function Bottom() {
  const { t } = useTranslation();

  return (
    <footer itemScope itemType='https://schema.org/Organization' className={classes.bottomContainer}>
      <div className={classes.bottom_info}>
        <div className={classes.forClients}>
          <Link itemProp='url' target='_blank' to='https://instagram.com/wooolama/'>
            Instagram
          </Link>
          <Link itemProp='url' target='_blank' to='https://facebook.com/groups/550137906752470'>
            Facebook
          </Link>
          <Link itemProp='url' to='https://t.me/LarisaSalmanova'>
            Telegram
          </Link>
          <Link itemProp='url' to='viber://chat?number=%2B380955025286'>
            Viber
          </Link>
          <Link itemProp='url' to='aboutuspage'>
            {t('static:aboutUs')}
          </Link>
        </div>
        <div className={classes.contacts}>
          <Link to='contacts'>{t('static:contacts')}</Link>
          <p itemProp='telephone'>+380955025286</p>
          <p itemProp='telephone'>+380957729338</p>
          <p itemProp='email'>sales@wooolama.com</p>
        </div>
        <div className={classes.otherRules}>
          <Link itemProp='url' to='publicoffer'>
            {t('static:publicOffer')}
          </Link>
          <Link itemProp='url' to='privacypolicy'>
            {t('static:privacyPolicy')}
          </Link>
          <Link itemProp='url' to='paymentanddelivery'>
            {t('static:paymentsAndDelivery')}
          </Link>
        </div>
      </div>
      <div className={classes.logo}>
        <img
          src={wooLamaLogo}
          itemProp='logo'
          title='wooolama.com магазин пряжі'
          width='400'
          height='200'
          alt='wooolama.com інтернет магазин пряжі'
        />
      </div>
    </footer>
  );
}
