export function removeOrderLocal(orderId) {
  let orders = JSON.parse(localStorage.getItem('orders'));
  if (orders) {
    let newOrdersList = orders.filter((order) => order.orderId !== orderId);
    if (!newOrdersList.length) {
      localStorage.removeItem('orders');
    } else {
      localStorage.setItem('orders', JSON.stringify(newOrdersList));
    }
  }
}
