import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { copyItems } from '../../helpers/copyItems';
import { schemaProductItemList } from '../../helpers/schemaProductItemList';
import { MainPageImage } from '../ImageMainPage/ImageMainPage';
import { DiscountCarusel } from '../OrdersManager/DiscountCarusel/DiscountCarusel';
import { OuterCatalogCardsContainer } from '../OuterCatalogCardsContainer/OuterCatalogCardsContainer';

import classes from './MainPage.module.scss';

export function MainPage({
  stateToRerenderBuyButton,
  setStateToRerenderBuyButton,
  setShoppingCardPreview,
  makeMainContentUnscrollable,
}) {
  const { t } = useTranslation();
  const { categoriesList } = useSelector((state) => state.GoodsReducer);
  const { catalogs } = useSelector((state) => state.CatalogReducer);

  const [outerCatalogs, setOuterCatalos] = useState([]);
  const [noOuterCatalogs, setNoOuterCatalogs] = useState({});
  const [noOuterCategoryCaltalogs, setNoOuterCategoryCatalogs] = useState([]);
  const [allCatalogs, setAllCatalogs] = useState([]);

  useEffect(() => {
    const filteredOuterCatalogs = categoriesList.filter(
      (outerCatalogObj) => outerCatalogObj.outerCategory !== 'noOuterCategory',
    );

    const filteredNoOuterCatalogs = categoriesList.find(
      (outerCatalogObj) => outerCatalogObj.outerCategory === 'noOuterCategory',
    );
    filteredOuterCatalogs && setOuterCatalos(filteredOuterCatalogs);
    filteredNoOuterCatalogs && setNoOuterCatalogs(filteredNoOuterCatalogs);
  }, [categoriesList]);

  useEffect(() => {
    const noOuterCategoryCatalos = [];

    noOuterCatalogs &&
      noOuterCatalogs?.categories &&
      noOuterCatalogs.categories.forEach((category) => {
        const localCatalog = catalogs.find((catalog) => catalog.category === category);
        if (localCatalog) noOuterCategoryCatalos.push(localCatalog);
      });

    noOuterCategoryCatalos.length && setNoOuterCategoryCatalogs(noOuterCategoryCatalos);
  }, [noOuterCatalogs, catalogs]);

  useEffect(() => {
    let outerCatalogsCopy = copyItems({ type: 'catalogs', currentItems: outerCatalogs, t });
    let noOuterCatagoryCatalogsCopy = copyItems({
      type: 'catalogs',
      currentItems: noOuterCategoryCaltalogs,
      t,
    });

    let allCatalogs = outerCatalogsCopy.concat(noOuterCatagoryCatalogsCopy);

    setAllCatalogs(allCatalogs);
  }, [outerCatalogs, noOuterCategoryCaltalogs]);

  return (
    <div className={classes.mainPageContainer}>
      <Helmet>
        <script className='structured-data-list' type='application/ld+json'>
          {schemaProductItemList(
            'Каталог товарів',
            'catalogs',
            '',
            allCatalogs,
            'Купити пряжу в Україні за низькими цінами. Пряжа Alize, Nako, YarnArt, Madame Tricote, Gazzal в моточках поштучно і бобінна пряжа на розмотку від 50 грам Yarn forn sale',
          )}
        </script>
        <meta
          name='description'
          content='Купити пряжу в інтернет-магазині Wooolama. Пряжа Alize, Nako, YarnArt, Madame Tricote, Gazzal в моточках поштучно і бобінна пряжа на розмотку від 50 грам'
        />
        <link rel='canonical' href={`https://wooolama.com`} />
      </Helmet>
      <MainPageImage />
      <OuterCatalogCardsContainer
        outerCatalogs={outerCatalogs}
        noOuterCategoryCaltalogs={noOuterCategoryCaltalogs}
      />
      <DiscountCarusel
        stateToRerenderBuyButton={stateToRerenderBuyButton}
        setStateToRerenderBuyButton={setStateToRerenderBuyButton}
        setShoppingCardPreview={setShoppingCardPreview}
        makeMainContentUnscrollable={makeMainContentUnscrollable}
      />
    </div>
  );
}
