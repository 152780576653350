import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CatalogCardPlaceholder } from '../CatalogCardsContainer/CatalogCardPlaceholder/CatalogCardPlaceholder';
import { CatalogCard } from '../CatalogCardsContainer/CatalogCard/CatalogCard';

import classes from './OuterCatalogCardsContainer.module.scss';

export function OuterCatalogCardsContainer({ outerCatalogs, noOuterCategoryCaltalogs }) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, []);

  const { t } = useTranslation();

  const [isOuterCatalogsLoaded, setIsOuterCatalogsLoaded] = useState(false);
  const [isNoOuterCategoryCatalogsLoaded, setIsNoOuterCatalogsLoaded] = useState(false);

  useEffect(() => {
    outerCatalogs && outerCatalogs.length && setIsOuterCatalogsLoaded(true);
  }, [outerCatalogs]);

  useEffect(() => {
    noOuterCategoryCaltalogs && noOuterCategoryCaltalogs.length && setIsNoOuterCatalogsLoaded(true);
  }, [noOuterCategoryCaltalogs]);

  return (
    <>
      <h1 className={classes.catalogTitle}>{t('static:catalog')}</h1>
      <div className={classes.OuterCatalogCardsContainer}>
        {isOuterCatalogsLoaded
          ? outerCatalogs.map((outerCatalog) => <CatalogCard catalog={outerCatalog} />)
          : [1, 2, 3, 4].map((_, idx) => <CatalogCardPlaceholder idx={idx} />)}
        {isNoOuterCategoryCatalogsLoaded
          ? noOuterCategoryCaltalogs.map((noOuterCatalog) => <CatalogCard catalog={noOuterCatalog} />)
          : [1, 2, 3, 4].map((_, idx) => <CatalogCardPlaceholder idx={idx} />)}
      </div>
    </>
  );
}
